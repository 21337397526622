<template>
  <div>
    <div>
      <img
        :src="message.file.url"
        class="qchat-conv__bubble__image qchat-conv__rounded__image q-pointer"
        @click="openImageInNewTab(message.file.url)"
      />
    </div>
    <div
      v-if="message.text"
      class="qchat-conv__bubble__message q-mb-0 q-mt-2"
      style="white-space: break-spaces"
    >
      <!-- <span
        style="white-space: pre-line"
        v-dompurify-html="formattedMessage"
      ></span> -->
      <span v-for="(content, idx) in contentFormatter(message.text)" :key="idx">
        <span
          v-dompurify-html="bracketClear(whatsappFormatter(content.textBefore))"
        ></span
        ><a
          :href="
            content.url.includes('://') ? content.url : `https://${content.url}`
          "
          style="word-break: break-all;"
          target="_blank"
          >{{ content.url }}</a
        ><span
          v-dompurify-html="bracketClear(whatsappFormatter(content.textAfter))"
        ></span>
      </span>
      <span
        v-if="message.text.length >= 253 && !isRead"
        class="q-pointer"
        @click="isRead = true"
        :style="{
          color:
            styles.conversation.bubbleChat[
              user.name === message.sender.name ? 'right' : 'left'
            ].mainColor === '#0474f5'
              ? '#234d8d'
              : '#0274f5',
        }"
        >Read more</span
      >
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
export default {
  props: {
    message: {
      type: Object,
      default: () => {},
    },
    imageLists: {
      type: [Object, Array],
      default: () => {},
    },
    listMode: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isRead: true,
    };
  },
  computed: {
    ...mapState('messenger', ['styles', 'user']),
    formattedMessage() {
      if (this.isRead)
        return this.whatsappToPreviewFormatter(this.message.text);
      else
        return this.whatsappToPreviewFormatter(
          this.sliceWords(this.message.text, 253)
        );
    },
  },
  methods: {
    createObjectURL(file) {
      return URL.createObjectURL(file);
    },
    openImageInNewTab(url) {
      window.open(url);
    },
  },
};
</script>
